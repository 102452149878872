/**
 * @generated SignedSource<<7667341958447b21e675f04f286b40a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnuminvoiceModelCreateTCOrderType = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "MOMENT_ORDER" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type EnuminvoiceModelCreateTCPaymentType = "BILL_UPLOAD" | "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type EnuminvoiceModelCreateTCStatus = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESSFUL" | "%future added value";
export type PaymentType = "CASH" | "JUSPAY" | "PAYTMQR" | "RAZORPAY" | "%future added value";
export type Referer = "ADDONS" | "EVENT" | "FOOD_ORDER" | "INVESTMENT_LEAD" | "MOMENT_ORDER" | "PARKING" | "POPUP_ORDER" | "POPUP_STORE" | "RESTAURANT" | "STORE" | "%future added value";
export type invoiceWorkshopDetailsInput = {
  slotId: any;
  tickets?: ReadonlyArray<InvoiceModelMomentsOrdersTCWorkshopDetailsTicketsInput | null> | null;
};
export type InvoiceModelMomentsOrdersTCWorkshopDetailsTicketsInput = {
  spaces: number;
  ticketId: any;
};
export type addonsDetailsInput = {
  orderId: any;
};
export type paymentAmountScreenQueryInitiatePaymentMutation$variables = {
  addons?: addonsDetailsInput | null;
  amount?: number | null;
  couponCode?: string | null;
  csrContributionId?: string | null;
  orderId: string;
  orderType: Referer;
  paymentType?: PaymentType | null;
  tipAmount?: number | null;
  useRewardPoints: boolean;
  userId?: string | null;
  workshopDetails?: invoiceWorkshopDetailsInput | null;
};
export type paymentAmountScreenQueryInitiatePaymentMutation$data = {
  readonly initiatePayment: {
    readonly _id: any;
    readonly created_at: any | null;
    readonly displayName: string;
    readonly orderId: string | null;
    readonly orderType: EnuminvoiceModelCreateTCOrderType;
    readonly paymentDetails: {
      readonly juspayOrder: {
        readonly _id: any;
        readonly amount: number;
        readonly orderId: string;
        readonly paymentLinks: {
          readonly web: string | null;
        } | null;
        readonly sdkPayload: any;
        readonly service: string | null;
      } | null;
      readonly paytmQr: {
        readonly qrImage: string | null;
        readonly transactionId: string | null;
      } | null;
    } | null;
    readonly paymentType: EnuminvoiceModelCreateTCPaymentType | null;
    readonly priceDetails: {
      readonly _id: any | null;
      readonly creditsUsed: number;
      readonly csrAmount: number;
      readonly discountGiven: number;
      readonly grossAmount: number;
      readonly netAmount: number;
      readonly rewardsUsed: number;
      readonly roundOffDeductionAmount: number | null;
      readonly tipAmount: number | null;
    };
    readonly status: EnuminvoiceModelCreateTCStatus | null;
    readonly userId: any | null;
  } | null;
};
export type paymentAmountScreenQueryInitiatePaymentMutation = {
  response: paymentAmountScreenQueryInitiatePaymentMutation$data;
  variables: paymentAmountScreenQueryInitiatePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addons"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "couponCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "csrContributionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderType"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentType"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tipAmount"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useRewardPoints"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workshopDetails"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderId",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "addons",
        "variableName": "addons"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "couponCode",
        "variableName": "couponCode"
      },
      {
        "kind": "Variable",
        "name": "csrContributionId",
        "variableName": "csrContributionId"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      },
      {
        "kind": "Variable",
        "name": "orderType",
        "variableName": "orderType"
      },
      {
        "kind": "Variable",
        "name": "paymentType",
        "variableName": "paymentType"
      },
      {
        "kind": "Variable",
        "name": "tipAmount",
        "variableName": "tipAmount"
      },
      {
        "kind": "Variable",
        "name": "useRewardPoints",
        "variableName": "useRewardPoints"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "workshopDetails",
        "variableName": "workshopDetails"
      }
    ],
    "concreteType": "invoiceModelCreateTC",
    "kind": "LinkedField",
    "name": "initiatePayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceModelMomentsOrdersTCPriceDetails",
        "kind": "LinkedField",
        "name": "priceDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grossAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountGiven",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rewardsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creditsUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roundOffDeductionAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tipAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "csrAmount",
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      (v12/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paymentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiePaymentDetailsTC",
        "kind": "LinkedField",
        "name": "paymentDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaytmDqrOrders",
            "kind": "LinkedField",
            "name": "paytmQr",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qrImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "juspayOrder",
            "kind": "LinkedField",
            "name": "juspayOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "service",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "juspayOrderPaymentLinks",
                "kind": "LinkedField",
                "name": "paymentLinks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "web",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sdkPayload",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentAmountScreenQueryInitiatePaymentMutation",
    "selections": (v13/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v10/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "paymentAmountScreenQueryInitiatePaymentMutation",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "e515f82912798cd9df9820ee23a4b114",
    "id": null,
    "metadata": {},
    "name": "paymentAmountScreenQueryInitiatePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation paymentAmountScreenQueryInitiatePaymentMutation(\n  $amount: Float\n  $orderType: Referer!\n  $orderId: ID!\n  $userId: ID\n  $csrContributionId: ID\n  $paymentType: PaymentType\n  $useRewardPoints: Boolean!\n  $tipAmount: Float\n  $workshopDetails: invoiceWorkshopDetailsInput\n  $couponCode: String\n  $addons: addonsDetailsInput\n) {\n  initiatePayment(amount: $amount, orderType: $orderType, orderId: $orderId, userId: $userId, paymentType: $paymentType, useRewardPoints: $useRewardPoints, tipAmount: $tipAmount, workshopDetails: $workshopDetails, couponCode: $couponCode, csrContributionId: $csrContributionId, addons: $addons) {\n    userId\n    displayName\n    priceDetails {\n      grossAmount\n      discountGiven\n      rewardsUsed\n      creditsUsed\n      roundOffDeductionAmount\n      netAmount\n      tipAmount\n      csrAmount\n      _id\n    }\n    status\n    _id\n    created_at\n    orderId\n    orderType\n    paymentType\n    paymentDetails {\n      paytmQr {\n        transactionId\n        qrImage\n      }\n      juspayOrder {\n        service\n        paymentLinks {\n          web\n        }\n        _id\n        orderId\n        amount\n        sdkPayload\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8583dfe12e3a989d98ffd419a08bf06d";

export default node;
