import { initializeApp } from 'firebase/app';
import { Platform } from 'react-native';
import { getAnalytics, logEvent } from 'firebase/analytics';
import AsyncStorage from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';
import Constants from 'expo-constants';
import { convertValuesToStringsDeep, filterObject } from '../utilities/Utility';
// eslint-disable-next-line import/order, import/no-extraneous-dependencies
import {
  track,
  revenue,
  Revenue,
  flush,
} from '@amplitude/analytics-react-native';
import { firebaseConfig, firebaseConfigStaging } from '../utilities/Constant';
import useUserStore, { UserRole } from '../stores/userStore';
import { navigationRef } from '../navigation/RootNavigation';
import AppConstants from './AppConstants';
import mixpanel from './mixpanelConfig';
import GoogleAnalytics from './googleAnalytics';
import useCommonStore from '../stores/commonStore';
import { paymentOrderTypeEnum } from './helper';

export enum InteractionType {
  TAP = 'tap',
  DATA = 'data',
  MISC = 'misc',
  SCREEN = 'screen',
  RESET = 'reset',
  LANDING = 'landing',
  TOGGLE = 'toggle',
  CHECKBOX = 'checkbox',
}

const webOrDev = __DEV__ || Platform.OS === 'web';

const analyticsModule = webOrDev
  ? null
  : require('@react-native-firebase/analytics');
const crashlyticsModule = webOrDev
  ? null
  : require('@react-native-firebase/crashlytics');
const perfModule = webOrDev ? null : require('@react-native-firebase/perf');

let webAnalytics = null;

if (Platform.OS === 'web' && !__DEV__) {
  const config =
    AppConstants.APP_ENV === 'production'
      ? firebaseConfig
      : firebaseConfigStaging;
  const app = initializeApp(config);
  webAnalytics = getAnalytics(app);
}

export const UNIQUE_DEVICE_ID = 'UNIQUE_DEVICE_ID';

interface setRevenueProps {
  amount?: number;
  quantity?: number;
  orderType?: paymentOrderTypeEnum;
}

export const setRevenue = ({
  amount = null,
  quantity = 0,
  orderType,
}: setRevenueProps) => {
  const event = new Revenue()
    .setProductId(orderType)
    .setPrice(amount)
    .setQuantity(quantity);

  revenue(event);
};

export const firebaseEventLogger = async (name = '', params = {}) => {
  try {
    // eslint-disable-next-line prettier/prettier,dot-notation
    delete params['time'];
    const navigationStack = navigationRef?.current?.getRootState();
    const currentNavigationState = navigationRef?.current?.getCurrentRoute();

    const currentScreenParams = currentNavigationState?.params;
    const utmParams = filterObject(currentScreenParams, [
      'utm_campaign',
      'utm_source',
      'utm_medium',
      'utm_term',
      'utm_device',
    ]);

    const currentScreen =
      navigationStack?.routes[navigationStack?.routes?.length - 1];
    const previousScreen =
      navigationStack?.routes[navigationStack?.routes?.length - 2];

    let currentScreenName = '';
    let previousScreenName = '';

    if (previousScreen?.state?.type === 'tab') {
      previousScreenName = `${previousScreen?.name}/${
        previousScreen?.state?.routeNames[previousScreen?.state?.index]
      }`;
    } else {
      previousScreenName = previousScreen?.name;
    }
    if (currentScreen?.state?.type === 'tab') {
      currentScreenName = `${currentScreen?.name}/${
        currentScreen?.state?.routeNames[currentScreen?.state?.index]
      }`;
    } else {
      currentScreenName = currentScreen?.name;
    }

    if (!currentScreenName) {
      currentScreenName = navigationRef?.current?.getState()?.routes[0]?.name;
    }

    const userId = useUserStore.getState().id;
    const userRole = useUserStore.getState().role;
    const isPlusMember = !!useUserStore.getState().blackMembership?.active;
    const deviceType = useCommonStore?.getState()?.deviceType;

    let deviceId = await AsyncStorage.getItem(UNIQUE_DEVICE_ID);
    const platform = Platform.OS;
    if (!deviceId) {
      // generate uuid4
      deviceId = String(uuid.v4());
      await AsyncStorage.setItem(UNIQUE_DEVICE_ID, deviceId);
    }

    params = {
      ...params,
      ...utmParams,
      userId,
      userRole,
      deviceId,
      platform,
      previousScreenName,
      currentScreenName,
      isPlusMember,
      appEnv: AppConstants.APP_ENV,
      appVersion: Constants?.expoConfig?.version,
      deviceType,
    };

    // firebase event disabled in dev mode
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log(
        'firebase event native: ',
        name,
        convertValuesToStringsDeep(params),
      );
    }
    const trimmedName = name ? name.trim() : '';
    if (!userRole || userRole === UserRole.USER) {
      mixpanel.track(trimmedName, params);
      if (
        Platform.OS === 'web' &&
        trimmedName.toLowerCase().includes('landing')
      ) {
        mixpanel.flush();
      }
    }
    track(trimmedName, params);
    if (Platform.OS === 'web') {
      await flush()
        .promise.then(() => {})
        .catch((err) => console.log('err---', err));
    }
    if (Platform.OS === 'web') {
      GoogleAnalytics.event(trimmedName, params);
    }
    if (Platform.OS !== 'web') {
      await analyticsModule.firebase.analytics().logEvent(trimmedName, params);
    }
  } catch (e) {
    // console.log('logging-error', e);
  }
};

export const setFirbaseUserProperties = async (data: any) => {
  if (Platform.OS === 'web' || __DEV__) return;
  await analyticsModule.firebase.analytics().setUserProperties(data);
};

export const setCrashlyticsCollectionEnabled = async () => {
  if (Platform.OS === 'web' || __DEV__) return;
  await crashlyticsModule.firebase
    .crashlytics()
    .setCrashlyticsCollectionEnabled(true);
};

export const setPerformanceEnable = async (value = false) => {
  if (Platform.OS === 'web' || __DEV__) return;
  await analyticsModule.firebase
    .analytics()
    .setAnalyticsCollectionEnabled(true);
  await perfModule.firebase.perf().setPerformanceCollectionEnabled(value);
};
