import 'expo-dev-client';
/* eslint-disable no-extra-boolean-cast */
import React, { lazy, Suspense, useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as ScreenOrientation from 'expo-screen-orientation';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react-native';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/no-extraneous-dependencies
import * as amplitude from '@amplitude/analytics-react-native';
import { ThemeProvider } from 'styled-components/native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Dimensions, LogBox, Platform } from 'react-native';
import { RelayEnvironmentProvider } from 'react-relay';
// eslint-disable-next-line import/no-extraneous-dependencies
import OTAConfig from './react-native.config';
import environment from './src/relay/relayEnvironment';
import { getTheme } from './src/themes/new/theme';
import { SnackBar } from './src/components/new';
import { setPerformanceEnable } from './src/utilities/firbaseAnalytics';
import DownloadApp from './src/components/modals/DownloadApp';
import useCommonStore from './src/stores/commonStore';
import Loading from './src/components/loading.component';
import '@expo/metro-runtime';
import AppConstants from './src/utilities/AppConstants';
import { isValueNullOrEmpty } from './src/utilities/Utility';

const AppNavigator = lazy(() => import('./src/navigation/appNavigator'));

LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs(); // Ignore all log notifications
SplashScreen.preventAutoHideAsync();

if (!__DEV__) {
  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;
  Sentry.init({
    dsn: 'https://44049234ccce4c7da2f97dc494a59d00@o489978.ingest.us.sentry.io/5553134',
    enableTracing: true,
    enableAutoPerformanceTracing: true,
    enableNative: true,
    enableCaptureFailedRequests: true,
    enabled: true,
    debug: !__DEV__,
    environment: AppConstants.APP_ENV,
    tracesSampleRate: 1,
    release: modifiedAppVersion,
  });
}

const loadAmplitudeSessionReplayScript = (src, attributes = {}) => {
  return new Promise((resolve, reject) => {
    const existingScript = Array.from(
      document.getElementsByTagName('script'),
    ).find((script) => script.src === src);

    if (existingScript) {
      resolve(true); // Script is already loaded
      return;
    }

    const script = document.createElement('script');
    script.src = src;

    // Add custom attributes
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
};

const initialisedSessionReplay = async (apiKey) => {
  try {
    // Load Amplitude Analytics Browser SDK
    await loadAmplitudeSessionReplayScript(
      'https://cdn.amplitude.com/libs/analytics-browser-2.9.0-min.js.gz',
    );
    console.log('Amplitude Analytics loaded successfully');

    // Load Amplitude Session Replay plugin
    await loadAmplitudeSessionReplayScript(
      'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.4.0-min.js.gz',
    );
    console.log('Amplitude Session Replay plugin loaded successfully');

    // Initialize Amplitude and the plugin
    const sessionReplayTracking = window.sessionReplay.plugin();
    window.amplitude.add(sessionReplayTracking);
    window.amplitude.init(apiKey);

    console.log('Amplitude initialized with Session Replay plugin');
  } catch (error) {
    console.error('Error loading Amplitude scripts:', error);
  }
};

const initiateAmplitude = () => {
  amplitude.init(process.env.AMPLITUDE_API_KEY, '', {
    // Events queued in memory will flush when number of events exceed upload threshold
    // Default value is 30
    flushQueueSize: 50,
    // Events queue will flush every certain milliseconds based on setting
    // Default value is 10000 milliseconds
    flushIntervalMillis: 20000,
    // logLevel: amplitude.Types.LogLevel.Debug,
  });
};

function App() {
  const theme = useCommonStore((state) => state.theme);
  const styledTheme = getTheme(theme);
  const screenOrientation = useCommonStore((state) => state.orientation);
  const setScreenOrientation = useCommonStore((state) => state.setOrientation);

  const [loaded] = useFonts({
    'NewYork-Regular': require('./assets/fonts/NewYork-Regular.ttf'),
    'MessinaSans-Bold': require('./assets/fonts/MessinaSans-Bold.otf'),
    'MessinaSans-Regular': require('./assets/fonts/MessinaSans-Regular.otf'),
    'CircularStd-Regular': require('./assets/fonts/CircularStd-Book.otf'),
    'CircularStd-Medium': require('./assets/fonts/CircularStd-Medium.otf'),
    'MixtaDidoneAlt-Medium': require('./assets/fonts/MixtaDidoneAlt-Medium.otf'),
    'Gotham-Regular': require('./assets/fonts/Gotham-Book.otf'),
    'Gotham-Medium': require('./assets/fonts/Gotham-Medium.otf'),
    'DomaineText-RegularItalic': require('./assets/fonts/DomaineText-RegularItalic.otf'),
    'UniversNextPro-Regular': require('./assets/fonts/UniversNextPro-Regular.otf'),
    IcoMoon: require('./assets/fonts/icomoon/fonts/icomoon.ttf'),
  });

  const windowWidth = Dimensions.get('window').width;

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (AppConstants.APP_ENV === 'production') {
        const script = document.createElement('script');
        script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '2116460215362701');fbq('track', 'PageView');`;
        document.head.appendChild(script);
      } else {
        const script = document.createElement('script');
        script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '28467469842852213');fbq('track', 'PageView');`;
        document.head.appendChild(script);
      }
    }
  }, []);

  const hideSplashScreen = async () => {
    await SplashScreen.hideAsync();
  };

  useEffect(() => {
    setPerformanceEnable(true);
    if (Platform.OS !== 'web') {
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    }
    ScreenOrientation.addOrientationChangeListener((event) => {
      setScreenOrientation(event?.orientationInfo?.orientation);
    });
    ScreenOrientation.getOrientationAsync()
      .then((value) => {
        setScreenOrientation(value);
      })
      .catch((err) => {
        setScreenOrientation(0);
        throw err;
      });
    return () => {
      ScreenOrientation.removeOrientationChangeListeners();
      setPerformanceEnable(false);
    };
  }, []);

  useEffect(() => {
    // Once our data is ready, hide the Splash Screen
    const checkUpdate = async () => {
      await hideSplashScreen();
    };
    if (loaded) {
      checkUpdate();
    }
    initiateAmplitude();
    if (Platform.OS === 'web') {
      initialisedSessionReplay(process.env.AMPLITUDE_API_KEY);
    }
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <ThemeProvider theme={styledTheme}>
      <ActionSheetProvider>
        <RelayEnvironmentProvider environment={environment}>
          <SafeAreaProvider
            style={
              windowWidth > 1200 && screenOrientation < 3
                ? { width: 375, alignSelf: 'center' }
                : {}
            }
          >
            <Suspense fallback={<Loading />}>
              <AppNavigator />
            </Suspense>
            <SnackBar />
            <DownloadApp />
          </SafeAreaProvider>
        </RelayEnvironmentProvider>
      </ActionSheetProvider>
    </ThemeProvider>
  );
}

export default Sentry.wrap(App);
