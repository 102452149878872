import React from 'react';
import styled from 'styled-components/native';
import { variant } from 'styled-system';
import { Image } from 'react-native';
import View from '../basic/View/View';
import { ThemeVariables } from '../../../themes/new/helper';
import Text from '../primitive/Typography/Text/Text';
import useUserStore from '../../../stores/userStore';
import { toShortHandNumber } from '../../../utilities/helper';

const sizeVariants = ({ theme }) => {
  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      0: {
        paddingY: 0,
        paddingX: theme.space.sm,
      },
      1: {
        paddingY: theme.space.sm,
        paddingX: theme.space.lg,
      },
    },
  });
};

const levelVariants = ({ theme }) => {
  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      0: {
        width: 8,
        height: 8,
      },
      1: {
        width: 16,
        height: 16,
      },
    },
  });
};

const StyledView = styled(View)`
border-width:${({ theme }) => theme.borderWidths.xs}
border-color:${({ theme }) => theme.colors.static.gold}
background-color:${({ theme }) => theme.colors.grey[10]}
border-radius:${({ theme }) => theme.radii.sm}
${(props) => sizeVariants(props)}
`;

const StyledText = styled(Text).attrs(({ level }) => ({
  size: level === 0 ? '2xs' : 'sm',
}))`
  ${({ theme }) => ({
    color: theme.colors.primary['500'],
  })}
`;

const StyledImage = styled(Image)`
  ${(props) => levelVariants(props)}
`;

interface PointsBalanceProps {
  level: number;
}

const PointsBalance = ({ level }: PointsBalanceProps) => {
  const { wallet, credits } = useUserStore((state) => state);
  const { currentBalance = 0 } = wallet ?? {};
  const { currentBalance: currentCreditBalance = 0 } = credits ?? {};

  const totalBalance = currentBalance + currentCreditBalance;

  return totalBalance ? (
    <StyledView
      level={level}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <StyledImage
        level={level}
        source={require('../../../../assets/images/coins.webp')}
        resizeMode="contain"
      />
      <StyledText level={level}>
        {level === 0
          ? `₹${toShortHandNumber(totalBalance ?? 0)}`
          : `₹${totalBalance ?? 0}`}
      </StyledText>
    </StyledView>
  ) : (
    <></>
  );
};

export default PointsBalance;
